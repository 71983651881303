import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import "./news.css";
class News extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <section id="news_section">
                    <img
                        src="images/news/Alhaj-Touhidul-Alam-Chy.jpg"
                        className="d-block w-100"
                    />
                </section>
                <Footer />
            </div>
        );
  }
}
export default News
